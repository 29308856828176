@import 'theme/colors.scss';

.live-parts-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: white;
  padding: 15px 30px;
  .anticon {
    padding: 0 10px;
  }
  h3.ant-typography {
    margin: 0;
    word-spacing: 2px;
  }
}
