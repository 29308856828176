@import 'theme/colors';

.section-wrapper {
  background-color: $white;
  padding: 20px 30px;
  height: 100%;
  h1.ant-typography {
    text-align: center;
  }
  .ant-form {
    margin-top: 20px;
  }
}
