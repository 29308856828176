@import 'theme/breakpoints';
@import 'theme/colors';

.login-background {
  background-image: url('../../assets/images/logo.jpg');
  height: 200px;
  margin: auto;
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  background-position: center;
}

.login-form {
  padding: 0 12px;
  margin: auto;
  background-color: $white;
  width: 32%;
  border-radius: 3px;
  border: 1px solid $boxShadow;
  max-width: 500px;
  padding-bottom: 10px;
  .ant-btn {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

@media (max-width: $lg) {
  .login-background {
    height: 150px;
  }
}

@media (max-width: $md) {
  .login-form {
    width: 50%;
  }
}
@media (max-width: $sm) {
  .login-form{
    width: 90%;
  }
}
