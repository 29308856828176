@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.site-layout {
  background-color: $background;
  height: 100%;
  min-height: 100vh !important;
  overflow-x: hidden;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
  overflow: hidden;
}

.fade-enter-active {
  opacity: 1;
  z-index: 1;
  transition: opacity 1s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
  display: none;
}

@media (max-width: $md) {
  .content-layout {
    padding-top: 30px;
  }
}
