@import 'theme/breakpoints.scss';

.custom-drop-down {
  width: 30%;
  float: left;
  margin-right: 100%;
}

@media (max-width: $lg) {
  .custom-drop-down {
    width: 50%;
  }
}
@media (max-width: $sm) {
  .custom-drop-down {
    width: 90%;
  }
}
