@import 'theme/colors';
@import 'theme/breakpoints';

.user-info-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
  .sub-info-wrapper:not(:last-child) {
    margin-left: 50px;
  }
}

.sub-info-wrapper {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid $boxShadow;
  border-radius: 3px;
  h3.ant-typography, .ant-typography + h3.ant-typography {
    margin: 5px 0;
  }
}

@media (max-width: $md) {
  .user-info-wrapper {
    flex-direction: column-reverse;
    margin: 30px 30px 10px;
    .sub-info-wrapper:not(:last-child) {
      margin: 0;
    }
  }
  .sub-info-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
