@import 'theme/colors.scss';

.image-card {
  .ant-card-body {
    padding: 0;
    min-height: 260px;
    background-color: $boxShadow;
  }
  img {
    width: 100%;
    min-height: 260px;
    opacity: 0.9;
  }
  h2.ant-typography {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    color: white;
    text-transform: uppercase;
    // text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
    z-index: 2;
    font-weight: bold;
  }
  &:after {
    content: "";
    position: absolute;
    background-color: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    top: 0;
}
}

.brand-card {
  .ant-card-body,
  img {
    height: 72px;
    min-height: 72px;
  }
}
