@import 'theme/antd-custom-theme.css';
@import 'theme/typography.scss';
@import 'theme/global.scss';

@import 'theme/colors.scss';

@import 'theme/breakpoints.scss';

@font-face {
  font-family: 'Markazi';
  src: url(assets/fonts/MarkaziText-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'Markazi-bold';
  src: url(assets/fonts/MarkaziText-Bold.ttf);
  font-weight: bold;
}

body {
  font-size: 20px;
  color: $primary;
}

@media (max-width: $md) {
  body {
    font-size: 18px;
  }
}

@media print {
  @page {
    size: landscape;
  }
  body * {
    visibility: hidden;
  }
}
