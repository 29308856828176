@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.custom-input {
  .ant-input,
  .ant-input-number {
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .ant-input:focus,
  .ant-input-number:focus,
  .ant-input-number-focused {
    box-shadow: none;
  }
}

.custom-alert {
  width: max-content;
  margin-bottom: 15px;
}

.ant-table {
  font-size: 18px;
}

.button-link {
  padding: 0 !important;
  height: auto !important;
  background-color: transparent !important;
  color: $secondary !important;
  font-size: 18px !important;
  :hover {
    text-decoration: underline;
  }
  span,
  span:hover {
    background-color: transparent !important;
  }
}

@media (max-width: $md) {
  .ant-table {
    font-size: 16px;
  }
}
