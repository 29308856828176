.ads-card {
  max-width: 300px;
}
.card-main-img {
  object-fit: cover;
  max-height: 100px;
}

.ads-wrapper {
  .ant-row {
    align-items: flex-start;
  }
}
