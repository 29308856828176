@import 'theme/colors';

.purchase-button {
  .anticon {
    margin-left: 10px;
  }
  span.ant-typography {
    color: $custom-input;
    margin-right: 10px;
    background-color: #f5f5f5;
    padding: 6px 15px;
    font-weight: bold;
    border-bottom: 1px solid $custom-border;
    border-radius: 3px;
    line-height: 2.5;
  }
}
