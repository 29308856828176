@import 'theme/colors';

.custom-subtitle {
  width: max-content;
  float: left;
  margin-right: 100%;
  border: 1px solid $custom-border;
  background-color: $background;
  padding: 5px 10px;
  margin-bottom: 15px;
}
