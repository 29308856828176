@import 'theme/breakpoints';
@import 'theme/colors';

.auth-form {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 12px;
  margin: auto;
  background-color: $white;
  width: 32%;
  border-radius: 3px;
  border: 1px solid $boxShadow;
  max-width: 500px;
  padding-bottom: 10px;
  .ant-btn-text {
    padding: 0;
  }
  .ant-btn-link {
    padding: 0;
  }
  .custom-form {
    margin: 20px 0 20px 0;
  }
  .ant-typography {
    text-align: center;
  }
  .ant-btn-primary {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  .custom-button {
    display: block;
    background-color: orange;
    border-color: orange;
    color: $white;
    margin: 0 auto 10px;
  }
  .custom-button:hover {
    background-color: orange;
    border-color: orange;
    color: $white;
    opacity: 0.7;
  }
  .custom-text {
    color: #337ab7;
  }
}
@media (max-width: $md) {
  .auth-form {
    width: 50%;
  }
}
@media (max-width: $sm) {
  .auth-form{
    width: 90%;
  }
}
