@import 'theme/breakpoints.scss';

.image-icon {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  margin: 5px;
  object-fit: cover;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: $sm) {
  .images-wrapper {
    max-width: 100%;
  }
}
