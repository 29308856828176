$primary: #354649;
$secondary:#a3c6c4;
$white: #ffffff;
$black: #000000;
$boxShadow: #00000028;
$background: #e0e7e9;
$custom-input: red;
$custom-border: #a3c6c4;
$error-color: #a94442;
$error-border: #ebccd1;
$error-background: #f2dede;
$custom-hover: #a3c6c4;
