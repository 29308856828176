@import 'theme/colors';

.custom-table {
  margin-top: 10px;
  .ant-table-wrapper-rtl {
    margin: 20px 0;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0;
  }
  .ant-table-thead > tr > th,
  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    font-weight: bold;
    text-align: center;
    background-color: $white;
  }
  .ant-table-tbody > tr > td {
    text-align: right;
  }
  .ant-input-number {
    border: none;
    border-bottom: 1px solid $custom-border;
    color: $custom-input;
  }
  .ant-input-number:focus, .ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input-number:hover {
    background-color: $background;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: $background;
    .ant-input-number, .ant-input {
      background-color: $background;
    }
  }
  .ant-table-tbody > tr:nth-child(2) > .custom-td {
    background-color: #00BB00;
  }
  .table-link {
    text-decoration: underline;
  }
  .custom-font-weight {
    font-weight: bold;
    direction: ltr;
  }
  .ant-pagination-options {
    display: inline-block !important;
  }
  .ant-pagination-options {
    display: inline-block !important;
  }
}

.custom-expandable-table {
  .ant-table-tbody > tr:hover {
    cursor: pointer;
  }
}

.custom-table-img {
  max-width: 80px;
  min-width: 80px;
  margin: 0px 5px;
}
