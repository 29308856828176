@import 'theme/breakpoints.scss';
@import 'theme/colors';

.report-table {
  margin-bottom: 20px;
  tr > td:first-child {
    background-color: #fafafa;
  }
  .ant-table-title {
    text-align: center;
  }
  .ant-table-thead > tr > th,
  .ant-table-title,
  tr > td:first-child {
    font-weight: bold;
  }
}

@media (max-width: $md) {
  .button-link {
    font-size: 16px;
  }
}
