.checkbox-group {
  width: 100%;
  .ant-list-rtl {
    margin-top: 10px;
  }
}

.loading-icon {
  font-size: 24px;
}
