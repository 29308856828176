@import 'theme/colors.scss';

.custom-form {
  .ant-form-item, .ant-alert-error {
    margin-bottom: 10px;
  }
  .ant-checkbox + span {
    font-weight: normal;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: $black;
  }
  .ant-alert-error {
    background-color: $error-background;
    border-color:$error-border;
    padding: 4px 10px;
    margin: 10px 0px;
    .ant-alert-message{
      color: $error-color;
    }
  }
}
