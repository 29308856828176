@import 'theme/colors';

.redirect-link {
  text-align: center;
  span.ant-typography {
    color: $custom-input;
    margin-left: 10px;
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
}
