@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

body {
  font-size: 18px;
  color: $black;
  font-family: 'Markazi';
}

h1.ant-typography {
  font-size: 30px;
}

h2.ant-typography {
  font-size: 23px;
}

h3.ant-typography {
  font-size: 20px;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography {
  font-weight: normal;
}
p.ant-typography {
  font-size: 20px;
}

span.ant-typography {
  font-size: 16px;
}

a {
  font-size: 18px;
}

label {
  font-weight: bold;
}

@media (max-width: $md) {
  h1.ant-typography {
    font-size: 26px;
  }

  h2.ant-typography {
    font-size: 20px;
  }

  h3.ant-typography {
    font-size: 18px;
  }

  p.ant-typography {
    font-size: 18px;
  }
}

@media (max-width: $sm) {
  a {
    font-size: 16px;
  }
  h1.ant-typography {
    font-size: 23px;
  }
}
