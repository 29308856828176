.list-view-wrapper {
  div {
    padding-bottom: 4px;
  }
}
.custom-table-list-wrapper {
  .ant-list-header,
  .list-title {
    font-weight: bold;
    text-align: center;
    width: 100%;
    grid-column: 1 / -1;;
  }
  .ant-list-item {
    margin: 10px 0;
  }
}
.list-grid-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}
