@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  background-color: $primary;
  .ant-menu-horizontal {
    line-height: 65px;
    font-size: 18px;
  }
  .ant-menu-dark .ant-menu-item * {
    color: $white;
  }
  .icon-ltr {
    margin-right: 10px !important;
    margin-left: 0 !important;
  }
  .ant-btn {
    box-shadow: none;
    height: 65px;
  }
  .ant-btn:hover {
    background: $custom-hover;
    border-color: $custom-hover;
    box-shadow: none;
  }
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title {
    color: $white;
  }
}

.custom-menu-item {
  float: left;
  display: flex;
  align-items: center;
  .ant-btn {
    direction: ltr;
  }
  .ant-btn-rtl.ant-btn > .anticon + span {
    margin-right: 0;
    margin-left: 8px;
  }
}

.sider-menu {
  border: none !important;
  margin-top: 20px !important;
  .ant-menu-submenu-inline {
    font-size: 16px;
  }
}

.logout-alert {
  padding: 15px 30px;
  text-align: center;
}

@media (max-width: $xl) {
  .site-header {
    display: block;
  }
}
